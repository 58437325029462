<template>
  <div id="list-wrapper">
    <ul v-if="filteredLocations && filteredLocations.features && filteredLocations.features.length" class="locations-list">
      <li v-for="location in filteredLocations.features" :key="location.properties.nid">
        <SingleLocation
        :location="location"
        :categories="categories"
        :tags="tags"
        viewMode="teaser"
        />
      </li>
    </ul>
    <div v-else class="item error">
      <p>No results found!</p>
    </div>
  </div>
</template>

<script>
import SingleLocation from './SingleLocation.vue'

export default{
  name: 'LocationsList',
  components: {
    SingleLocation,
  },
  props: {
    locations: {type: Object, required: true},
    categories: {type: Object, required: true},
    tags: {type: Object, required: true}
  },
  computed: {
    filteredLocations: function() {
      return this.getFilteredLocations()
    }
  },
  inject: [
    'getFilteredLocations'
  ]
}
</script>

<style lang="less" scoped>
ul.locations-list{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr) );
  // gap: 1em;
  padding: 0;
  li{
    list-style: none;
    flex: 1 1 20em;
  }
}

</style>
