<template>
  <transition name="overlay-bottom">
    <div v-if="content" class="overlay-wrapper" :class="side" v-on:click.self="showLocation()">
      <SingleLocation :location="content" :categories="categories" :tags="tags" viewMode="overlay" />
    </div>
  </transition>
  <transition name="fade">
    <div v-if="content" class="overlay background" @click="showLocation()"></div>
  </transition>
</template>

<script>
import SingleLocation from '@/components/SingleLocation.vue'

export default{
  name: 'OverlayWrapper',
  components: {
    SingleLocation,
  },
  props: {
    content: {type: Object, required: false}, // the content to display
    categories: {type: Object, required: true}, // provide vocabulary of categories including their icons
    tags: {type: Object, required: true}, // provide vocabulary of tags
    side: {type: String, required: true}, // which side the overlay is coming from
    viewMode: {type: String, required: false}
  },
  inject: [
    'showLocation'
  ]

}
</script>

<style lang="less" scoped>
.overlay-wrapper{
  z-index: 10;
  position: fixed;
}
.overlay-wrapper.bottom{
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 var(--side-padding);
}
.overlay-wrapper.right{
  top: 2em;
  right: 0;
  bottom: 0;
  padding: var(--side-padding) 0;
}
.overlay-wrapper.left{
  top: 2em;
  left: 0;
  bottom: 0;
  padding: var(--side-padding) 0;
}

</style>
