<template>
  <div class="fullscreen-view">
    <h2>Kategorien</h2>
    <div id="list-wrapper">
      <div class="button reset-filter" @click="setFilter(null)">Alle Angebote anzeigen</div>
      <ul class="category-list">
        <li v-for="category in categories" :key="category.tid">
          <SingleCategory :category="category" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SingleCategory from '@/components/SingleCategory.vue'

export default{
  name: 'CategoryView',
  components: {
    SingleCategory,
  },
  props: [
    'locations',
    'categories',
    'tags',
  ],
  inject: [
    'setFilter'
  ]
}
</script>

<style lang="less" scoped>
ul.category-list{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr) );
  gap: 0.5em;
  padding: 0;
  li{
    list-style: none;
    flex: 1 1 20em;
  }
}


</style>
