<template>
  <header>
    <div class="logo pointer" @click="changePage('MapView');setFilter(null)">
      <InlineSvg name="logo" />
    </div>
    <nav class="main-nav">
      <ul>
        <li class="toggle" v-show="selectedPage != 'MapView'" @click="changePage('MapView')"><InlineSvg name="map" /></li>
        <li class="toggle" v-show="selectedPage != 'ListView'" @click="changePage('ListView')"><InlineSvg name="list" /></li>
        <li class="search" @click="changePage('SearchView')"><InlineSvg name="search" /></li>
        <li class="separator"></li>
        <li class="toggle" @click="navVisible = !navVisible"><InlineSvg name="menu" /></li>
      </ul>
    </nav>
  </header>
  <main>
    <LoadingOverlay :loading="loading"></LoadingOverlay>
    <component :is="selectedPage" :locations="locations" :categories="categories" :tags="tags"></component>
    <OverlayWrapper :content="spotlight" :categories="categories" :tags="tags" viewMode="overlay" side="bottom" />
    <MobileNav :navVisible="navVisible" :categories="categories" />
    <PopUp v-if="popup" :popup="popup"></PopUp>
  </main>
  <!--<footer v-show="true" id="footer">© patrickitten.ch</footer>-->
</template>

<script>
import MapView from '@/views/MapView.vue'
import ListView from '@/views/ListView.vue'
import InfoView from '@/views/InfoView.vue'
import CategoryView from '@/views/CategoryView.vue'
import SearchView from '@/views/SearchView.vue'
import OverlayWrapper from '@/components/OverlayWrapper.vue'
import SingleLocation from '@/components/SingleLocation.vue'
import MobileNav from '@/components/MobileNav.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import PopUp from '@/components/PopUp.vue'


export default {
  name: 'App',

  created() {
    this.loading = true;
    Promise.all([
      this.getPopup(),
      this.getLocations(),
      this.getCategories(),
      this.getTags(),
    ]).then(v => {
      this.popup = v[0];
      this.locations = v[1];
      this.categories = v[2];
      this.tags = v[3];
      this.loading = false;
      // console.log("-> API calls successful");
    })
  },
  components: {
    MapView,
    ListView,
    InfoView,
    CategoryView,
    SearchView,
    OverlayWrapper,
    SingleLocation,
    MobileNav,
    LoadingOverlay,
    PopUp,
  },
  data() {
    return {
      locations:    [],
      categories:   [],
      tags:         [],
      popup:        null,
      selectedPage: 'MapView', // default View
      historyPage:  'MapView',
      spotlight:    null,
      navVisible:   false,
      filter:       null,
      loading:      false,
    }
  },
  computed: {
    filteredLocations: function() {
      if (!this.locations.features){
        return {} // no locations loaded
      }
      let filtered = this.locations.features // no filter
      if (this.filter && this.filter.type === 'category'){ // category filter
        filtered = this.locations.features.filter(obj => {
          return obj.properties.category === this.filter.id;
        });
      } else if (this.filter && this.filter.type === 'tag') { // tags filter
        filtered = this.locations.features.filter(obj => {
          let tagsArr = obj.properties.tags.split(/\s*,\s*/)//.map(Number)
          return tagsArr.includes(this.filter.id);
        });
      } else if (this.filter && this.filter.type === 'solo') { // solo filter
        filtered = this.locations.features.filter(obj => {
          return obj.properties.nid === this.filter.id;
        });
      }
      return {
        type: "FeatureCollection",
        features: filtered,
      }
    }
  },
  methods: {
    async getLocations(){
      let response = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/v1/locations?_format=json');
      let data = await response.json();
      // this.locations = data;
      return data;
    },
    async getCategories(){
      let response = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/v1/categories?_format=json');
      let data = await response.json();
      // data = data.sort((a,b) => {return b.count - a.count}) // sort by count
      // this.categories = data;
      return data;
    },
    async getTags(){
      let response = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/v1/tags?_format=json');
      let data = await response.json();
      // this.tags = data;
      return data;
    },
    async getPopup(){
      let response = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/v1/popup?_format=json');
      let data = await response.json();
      return data[0] ?? null;
    },
    changePage(page){
      if (page === 'MapView' || page === 'ListView') this.historyPage = page // save last viewmode
      this.selectedPage = page // change page
      this.showLocation() // hide detail view
      this.navVisible = false // hide nav
      // if (page) console.log("-> show " + page) // console
      if (page) window._paq.push(['trackEvent', 'Page', 'show-page', page]) // inform matomo
    },
    showLocation(nid){
      this.navVisible = false
      let index = nid ? this.locations.features.findIndex(feature => feature.properties.nid === nid) : null // get index in locations.features
      let name = nid ? this.locations.features[index].properties.name : '' // get name of location
      this.spotlight = index !== null ? this.locations.features[index] : null // set spotlight
      // if (nid) console.log("-> show " + name + " (nid:" + nid + ")") // console
      if (nid) window._paq.push(['trackEvent', 'Location', 'show-location', name]) // inform matomo
    },
    setFilter(filter){
      this.filter = filter
      this.changePage(this.historyPage)
      // todo : trigger map zoom to bounds

      let name = null
      if (filter && filter.type === 'category'){
        name = this.categories[this.categories.findIndex(item => item.tid === filter.id)].name // get cagegory name
      } else if (filter && filter.type === 'tag'){
        name = this.tags[this.tags.findIndex(item => item.tid === filter.id)].name // get tag name
      }
      // if (name) console.log("-> filter " + filter.type + " " + name) // console
      if (name) window._paq.push(['trackEvent', 'Filter', 'filter-'+filter.type, name]) // inform matomo
      // if (!name) window._paq.push(['trackEvent', 'Filter', 'clear', 'clear']) // inform matomo
    },
    getFilter(){
      return this.filter
    },
    getFilteredLocations(){
      return this.filteredLocations
    },
  },
  provide() {
    return{
      showLocation: this.showLocation,
      changePage: this.changePage,
      getFilteredLocations: this.getFilteredLocations,
      setFilter: this.setFilter,
      getFilter: this.getFilter,
    }
  }
}
</script>

<style lang="less">
@import "./less/defaults";
@import "./less/fonts";

*{touch-action: pan-y;}
html{
  height: -webkit-fill-available;
}
body{
  margin: 0;
  height:100%;
  width:100%;
  overflow: hidden auto;
}
#app {
  font-family: var(--font-family-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  width: 100%;
  height: 100%;
  -webkit-height: -webkit-fill-available;
  margin: 0 auto;

  header{
    padding: 0.25em 1em;
    position: fixed;
    top:0;
    right:0;
    left:0;
    z-index:20;
  }
  main{
    height:100%;
    // -webkit-height: -webkit-fill-available;
  }
  footer{
    position: absolute;
    bottom:10px;
    right:40px;
    text-align: center;
    font-size: 0.8em;
    padding: 0.2em;
  }
}

header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 20px fade(black, 10%);
  .logo{
    width: 7em;
    color: white;
  }
  nav.main-nav{
    ul{
      margin:0;
      display: flex;
      gap: 0.5em;
      li{
        list-style: none;
        width: 1.4em;
        fill: @color-base;
        &.separator{
          border-left: 2px solid @color-accent;
          width:0;
        }
      }
    }
  }
}

h1, h2, h3, h4, h5, h6{
  font-family: var(--font-family-sans);
  margin: 1em 0 0.5em;
}
h1{ font-size: 1.8rem; }
h2{ font-size: 1.5rem; }
h3{ font-size: 1.2rem; }
h4{ font-size: 1rem; }
p{
  margin: 0 0 0.75em 0;
}

hr{
  border-width: 1px;
  border-style: solid;
  border-color: @color-accent;
  margin: 1.5em 0;
}

.fullscreen-view{
  width:100%;
  overflow: hidden auto;
  box-sizing: border-box;
  padding-top: 4rem;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
}

div.overlay.background{
  background-color: var(--overlay-color);
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}

.pointer{
  cursor: pointer;
}

// Slide right transition
.nav-enter-from,
.nav-leave-to{
  transform: translateX(100%);
}
.nav-enter-to,
.nav-leave-from{
  transform: translateX(0%);
}
.nav-enter-active,
.nav-leave-active{
  transition: all 0.2s ease;
}

// Slide up transition
.overlay-bottom-enter-from,
.overlay-bottom-leave-to{
  transform: translateY(100%);
}
.overlay-bottom-enter-to,
.overlay-bottom-leave-from{
  transform: translateY(0%);
}
.overlay-bottom-enter-active,
.overlay-bottom-leave-active{
  transition: all 0.2s ease;
}

// Fade transition
.fade-enter-from,
.fade-leave-to{
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from{
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active{
  transition: all 0.5s ease;
}


</style>
