<template>
  <!-- teaser -->
  <div v-if="viewMode == 'teaser'" class="location" :class="viewMode" @click="showLocation(location.properties.nid)">
    <div class="icon">
      <InlineSvg :name="getIconName(location.properties.category)" />
    </div>
    <div class="info">
      <h2 v-html="location.properties.name"></h2>
      <p class="slogan" v-html="location.properties.slogan"></p>
      <p class="address" v-html="location.properties.address_line1"></p>
    </div>
  </div>
  <!-- other viewmodes -->
  <div v-else class="location" :class="viewMode">
    <div class="location-inner">
      <div class="hero">
        <img width="2048" height="1152" :src="location.properties.hero_image"/>
      </div>
      <div class="info">
        <h2 v-html="location.properties.name"></h2>
        <p v-html="location.properties.slogan"></p>
        <div class="centered">
          <div class="button" @click="setFilter({'type':'solo','id':location.properties.nid}); changePage('MapView')">auf der Karte anzeigen</div>
        </div>
        <h3 v-if="location.properties.description">Beschreibung</h3>
        <div v-html="location.properties.description"></div>
        <h3 v-if="location.properties.address">Adresse</h3>
        <div v-html="location.properties.address"></div>
        <h3 v-if="location.properties.opening_hours">Öffnungszeiten</h3>
        <div v-html="location.properties.opening_hours"></div>
        <div v-if="location.properties.link" class="centered">
          <a :href="location.properties.link" target="_blank" class="button ext-link">Webseite anzeigen</a>
        </div>
      </div>
    </div>
    <button @click="showLocation()" class="close"><InlineSvg name="close" /></button><!-- todo insert close button -->
  </div>
</template>

<script>
export default {
  name: 'SingleLocation',
  props: {
    location: {type: Object, required: true}, // the single location to display
    categories: {type: Object, required: true}, // provide vocabulary of categories including their icons
    tags: {type: Object, required: true}, // provide vocabulary of tags
    viewMode: {type: String, required: false}, // different ways to display location
  },
  data(){
    return {
      icon: 'flag-flag',
    }
  },
  methods: {
    getIconName(category){
      return this.categories.length ? this.categories.find(obj => obj.tid === category).icon || 'flag-flag' : 'flag-flag';
    }
  },
  inject: [
    'showLocation',
    'setFilter',
    'changePage',
  ]
}
</script>

<style lang="less" scoped>
@import "../less/defaults";
  .location{
    padding: 0em;
    .hero img{
      max-width: 100%;
      height: auto;
      background-color: @color-accent;
    }
    .info{
      padding: 0.25em 0.5em 0;
      :deep(p){
        margin-bottom: 0.25em;
      }
      p.address{
        font-size: 0.9em;
      }
      .button{
        margin-top: 1.5em;
        margin-bottom: 1.5em;
      }
    }
  }
  .location.teaser{
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid @color-accent;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    .icon{
      flex: 0 0 3em;
      margin-top:0.4em;
    }
    .info{
      flex: 1 1 auto;
      h1, h2, h3, h4{
        margin-top: 0;
        margin-bottom: 0;
      }
      h2{
        font-size: 1.1em;
      }
    }
  }
  .location.overlay{
    position: relative;
    margin: 0 auto;
    max-width: 30em;
    box-shadow: 0px 0px 20px fade(black, 40%);
    .location-inner{
      max-height: 72vh;
      overflow: auto;
      background-color: white;
      border-radius: 4px 4px 0 0;
      .info{
        padding: 0 var(--side-padding) var(--side-padding);
        h3{
          font-size: 1.1em;
        }
      }
    }
    button.close{
      position: absolute;
      top: -0.5em;
      right: -0.5em;
      margin:0;
      padding: 0.25em;
      background: white;
      border: none;
      width:  2.5em;
      height: 2.5em;
      border-radius:99999px;
      cursor: pointer;
    }
  }
  .centered{
    text-align: center;
  }
</style>
