<template>
  <div class="fullscreen-view">
    <h2>Angebote in Romanshorn</h2>
    <div class="button reset-filter" v-if="getFilter()" @click="setFilter(null)">Alle Angebote anzeigen</div>
    <LocationsList :locations="locations" :categories="categories" :tags="tags"/>
  </div>
</template>

<script>
import LocationsList from '@/components/LocationsList.vue'

export default{
  name: 'ListView',
  components: {
    LocationsList,
  },
  props: [
    'locations',
    'categories',
    'tags',
  ],
  inject: [
    'getFilter',
    'setFilter',
  ]
}
</script>

<style lang="less" scoped>
</style>
