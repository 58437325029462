<template>
  <transition name="fade">
    <div v-if="loading" class="loading">
      <div class="spinner">
        <span class="loader"></span>
      </div>
    </div>
  </transition>
</template>

<script>

export default{
  name: 'LoadingOverlay',
  props: {
    loading: {type: Boolean, required: false},
  }
}
</script>

<style lang="less" scoped>
@import "../less/defaults";
.loading{
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: fade(white, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner{
  font-size: 3em;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid @color-yellow;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.loader:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid @color-yellow;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}
</style>
