<template>
  <transition name="nav">
    <aside v-if="navVisible" class="navigation">
      <ul>
        <li><a @click="changePage('SearchView')"><InlineSvg name="search"></InlineSvg>Suche</a></li>
        <li><a @click="changePage('CategoryView')"><InlineSvg name="filter"></InlineSvg>Kategorien</a></li>
        <li><a @click="changePage('MapView')"><InlineSvg name="map"></InlineSvg>Karte</a></li>
        <li><a @click="changePage('ListView')"><InlineSvg name="list"></InlineSvg>Liste</a></li>
        <li><a @click="changePage('InfoView')"><InlineSvg name="info"></InlineSvg>Informationen</a></li>
      </ul>
    </aside>
  </transition>
  <transition name="fade">
    <div v-if="navVisible" class="overlay background" @click="showLocation()"></div>
  </transition>
</template>

<script>
export default {
  name: 'MobileNav',
  props: {
    categories: {type: Object, required: true}, // provide vocabulary of categories including their icons
    navVisible: {type: Boolean, required: true}
  },
  data(){
    return {
    }
  },
  methods: {
  },
  inject: [
    'changePage',
    'showLocation',
  ]
}
</script>

<style lang="less" scoped>
@import "../less/defaults";
  aside.navigation{
    background-color: white;
    z-index: 15;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    max-width:100%;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px fade(black, 7.5%);
    padding: 4.5em 1.5em 0;
    font-family: var(--font-family-sans);
    ul{
      margin:0;
      padding:0;
      li{
        list-style: none;
        margin-bottom: 0.5em;
        font-size:1.4em;
        a{
          text-decoration: none;
          color: var(--color-base);
          div[class^=icon]{
            display: inline-block;
            width: 0.8em;
            margin-right: 0.25em;
          }
        }
      }
    }

    button.close{
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      margin:0;
      padding: 0.25em;
      background: white;
      border: none;
      width:  2.5em;
      height: 2.5em;
      border-radius:99999px;
    }
  }
</style>
