<template>
  <div :class="computedIconClass">
    <VueInlineSvg
      :src="iconSrc"
      class="svg-icon"
      :class="computedIconClass"
      :style="cssProps"
    />
  </div>
</template>

<script>
import VueInlineSvg from "vue-inline-svg";

export default {
  name: 'InlineSvg',
  components: {
    VueInlineSvg,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    fallback: {
      type: String,
      default: 'flag-flag', // Default-Fallback-Icon
    }
  },
  data() {
    return {
      iconSrc: null, // Speichert die tatsächliche Icon-Quelle
      isFallback: false, // Wird auf true gesetzt, wenn das Fallback verwendet wird
    };
  },
  computed: {
    cssProps() {
      return {
        '--icon-color': this.color,
      };
    },
    computedIconClass() {
      // Wenn Fallback aktiv ist, hänge die Klasse 'fallback-icon' an
      let baseClass = 'icon-' + (this.isFallback ? this.fallback : this.name);
      if (this.isFallback) {
        baseClass += ' fallback-icon';
      }
      return baseClass;
    }
  },
  created() {
    this.loadIcon();
  },
  methods: {
    loadIcon() {
      try {
        // Versuch, das gewünschte Icon dynamisch zu laden
        this.iconSrc = require(`@/assets/${this.name}.svg`);
        this.isFallback = false; // Kein Fallback nötig
      } catch (error) {
        console.warn(`Icon "${this.name}" not found, using fallback "${this.fallback}".`);
        // Wenn das Icon nicht gefunden wird, Fallback-Icon verwenden
        this.iconSrc = require(`@/assets/${this.fallback}.svg`);
        this.isFallback = true; // Fallback aktivieren
      }
    }
  }
};
</script>

<style lang="less">
svg {
  width: 100%;
  height: auto;
  fill: var(--icon-color);
}
</style>
