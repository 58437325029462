<template>
  <div class="fullscreen-view">
    <h2>Suche</h2>
    <div class="search-group">
      <input type="text" id="search" v-model="input" placeholder="Angebote suchen..." />
      <div class="search-icon"><InlineSvg name="search"></InlineSvg></div>
      <button class="clear" @click="input=''"><InlineSvg name="close"></InlineSvg></button>
    </div>
    <div id="list-wrapper">
      <ul class="tag-list">
        <li v-for="tag in filteredList" :key="tag.tid">
          <SingleTag :tag="tag" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InlineSvg from '@/components/InlineSvg.vue';
import SingleTag from '@/components/SingleTag.vue';

export default{
  name: 'SearchView',
  data(){
    return {
      input: ''
    }
  },
  components: {
    SingleTag,
    InlineSvg
},
  props: [
    'locations',
    'categories',
    'tags',
    'searchString',
  ],
  mounted(){
    document.getElementById("search").focus()
  },
  computed: {
    filteredList(){
      if (this.input && this.input.length >= 0){
        let filtered = this.tags.filter((tag) =>
          tag.name.toLowerCase().includes(this.input.toLowerCase())
        )
        filtered.sort((a,b) => b.count - a.count )
        return filtered
      }
      return [];
    }
  },
  inject: [
    'getFilter',
    'setFilter',
  ]
}
</script>

<style lang="less" scoped>
@import "../less/defaults";
div.search-group{
  position: relative;
  input{
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-size: 1em;
    padding: 0.5em 1.75em;
    border-radius: 4px;
    border: 1px solid @color-accent;
    font-size: 1.2em;
    color: black;
    &:focus{
      outline: none;
      border: 1px solid @color-blue;
    }
  }
  button.clear, div.search-icon{
    position: absolute;
    top:50%;
    transform: translateY(-50%);
  }
  div.search-icon{
    left: 0.5em;
    width: 1.2em;
    :deep(svg){
      fill: @color-accent-darker;
    }
  }
  button.clear{
    right: 0.5em;
    padding: 0;
    border: none;
    background: none;
    width: 1.5em;
    :deep(svg){
      fill: @color-accent-darker;
    }
  }
}
ul.tag-list{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr) );
  gap: 0.5em;
  padding: 0;
  li{
    list-style: none;
    flex: 1 1 20em;
  }
}


</style>
