<template>
  <!--<MapboxMap :locations="locations" :categories="categories" :tags="tags"/>-->
  <div class="fullscreen-view map">
    <div class="button reset-filter" v-if="getFilter()" @click="setFilter(null), flyToBounds(filteredLocations)">Alle Angebote anzeigen</div>
    <div id="map-wrapper">
      <MapboxMap
        ref="map"
        :access-token="access_token"
        :map-style="map_style"
        :center="center"
        :zoom="zoom"
        :minPitch=0
        :maxPitch=0
        :minZoom=12
        :maxZoom=18
        :maxBounds="bounds"
        :dragRotate=false
        :pitchWithRotate=false
        :touchPitch=false
        :touchZoomRotate=true
        logoPosition="top-left"
        @mb-created="(mapboxInstance) => map = mapboxInstance">

        <MapboxNavigationControl position="bottom-left"/>
        <MapboxGeolocateControl position="bottom-left" />

        <!--<MapboxCluster
          :data="locations"
          :clusterRadius="40"
          :clusterMaxZoom="16"
          :clustersPaint="{
            'circle-color': 'rgba(128,128,128,0.5)',
            'circle-radius': 30,
          }"
          :unclusteredPointPaint="{
            'circle-radius': 0
          }"
        />--> <!-- improve with https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/ -->

        <div class="markers" v-for="location in filteredLocations.features" :key="location.properties.nid">
          <MapboxMarker
            :lng-lat="location.geometry.coordinates"
            anchor="bottom-left"
          >
            <InlineSvg :name="getIconName(location.properties.category)" @click="showLocation(location.properties.nid),flyToBounds({features: [location]},true)"/>
          </MapboxMarker>
        </div>

      </MapboxMap>
    </div>
  </div>
</template>

<script>
// import MapboxMap from '@/components/MapboxMap.vue'
import 'mapbox-gl/dist/mapbox-gl.css';
import { MapboxMap, MapboxMarker, MapboxNavigationControl, MapboxGeolocateControl } from '@studiometa/vue-mapbox-gl';
// import mapboxGl from 'mapbox-gl';
// import { MapboxMap, MapboxMarker, MapboxCluster, MapboxNavigationControl, MapboxGeolocateControl } from '@studiometa/vue-mapbox-gl';


export default{
  name: 'MapView',
  props: {
      locations: {type: Object, required: true},
      categories: {type: Object, required: true},
      tags: {type: Object, required: true}
    },
  components: {
    MapboxMap,
    MapboxMarker,
    // MapboxCluster,
    MapboxNavigationControl,
    MapboxGeolocateControl,
  },
  data() {
    return {
      map: null,
      access_token: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      map_style: "mapbox://styles/piridium/ckoiit3rg08iw18mwh6skt3x8",
      center: [9.378, 47.565],
      zoom: 14,
      icon: '',
      bounds: [
        [9.32, 47.52], // Southwest coordinates 9.335,47.55
        [9.43, 47.62] // Northeast coordinates 9.4,47.59
      ],
    };
  },
  computed: {
    filteredLocations: function() {
      return this.getFilteredLocations()
    }
  },
  mounted(){
    this.flyToBounds(this.filteredLocations)
  },
  methods: {
    getIconName(category){
      return this.categories.find(obj => obj.tid === category).icon || 'flag-flag';
    },
    flyToBounds(locs, offset = false){
      if (JSON.stringify(locs) === "{}") return
      const coords = locs.features.map(feature => feature.geometry.coordinates)
      let sw = [Infinity,Infinity]
      let ne = [-Infinity,-Infinity]
      // calculate bounding box
      coords.forEach(pt => {
        sw[0] = Math.min(pt[0],sw[0])
        sw[1] = Math.min(pt[1],sw[1])
        ne[0] = Math.max(pt[0],ne[0])
        ne[1] = Math.max(pt[1],ne[1])
      });
      // set margin
      const margin = 0.002
      sw[0] = sw[0] - margin
      sw[1] = sw[1] - margin*2
      ne[0] = ne[0] + margin
      ne[1] = ne[1] + margin*3
      // add offset
      if (offset) sw[1] = sw[1] - 0.0025
      // enforce global bounds
      sw[0] = Math.max(sw[0], this.bounds[0][0])
      sw[1] = Math.max(sw[1], this.bounds[0][1])
      ne[0] = Math.min(ne[0], this.bounds[1][0])
      ne[1] = Math.min(ne[1], this.bounds[1][1])

      this.map.fitBounds([sw, ne])
    }
  },
  inject: [
    'showLocation',
    'getFilteredLocations',
    'setFilter',
    'getFilter',
  ]
}
</script>

<style lang="less" scoped>
  .fullscreen-view.map{
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  #map-wrapper{
    height:100vh;
    width:100vw;
    text-align: left;

    // prevent vertical scrolling
    height:100%;
    width:100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    :deep(.mapboxgl-map),
    :deep(.mapboxgl-canvas-container),
    :deep(canvas){
      height:100%;
    }
  }
  #map-container{
    height: 100%;

    // prevent vertical scrolling
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
  }
  #map-container{
    :deep(.custom-marker),
    :deep(.mapboxgl-marker){
      width: 3em;
      height: 3em;
      background-color: red;
      border-radius: 25%;
    }
  }
  :deep(.mapboxgl-marker div[class^=icon-flag]) {
    width: 4em;
    height: auto;
  }
  :deep(.mapboxgl-marker div[class^=icon-sign]) {
    width: 2.75em;
    height: auto;
  }
  .button.reset-filter{
    position: fixed;
    top: 3.25em;
    left:50%;
    transform: translateX(-50%);
    z-index:1;
    white-space: nowrap;
  }
</style>
