<template>
  <div class="fullscreen-view info-view">
    <h1>Über dieses Projekt</h1>
    <p>"Romanshorn entdecken" ist eine gemeinsame Initiative der Fachgeschäfte in Romanshorn, des Gewerbevereins und der Stadt Romanshorn.</p>
    <p>Wir laden Sie ein, das lokale Angebot zu entdecken und freuen uns auf Ihren Besuch.</p>

    <hr/>

    <h2>Impressum</h2>

    <h3>Verantwortlich für die Inhalte</h3>
    <p>FARO - Fachgeschäfte Romanshorn</p>

    <h3>Grafik und Design</h3>
    <p class="pointer" @click="showLocation('162')">Ströbele Kommunikation</p>
    <p class="pointer" @click="showLocation('1')">Patrick Itten | Foto Grafik Web</p>

    <h3>Realisation Web App / Webmaster</h3>
    <p class="pointer" @click="showLocation('1')">Patrick Itten | Foto Grafik Web</p>

    <hr/>

    <h2>Datenschutz</h2>
    <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
    <p>FARO - Fachgeschäfte Romanshorn<br/>
    &#8453; Gewerbeverein Romanshorn und Umgebung<br/>
    8590 Romanshorn<br/>
    info@gvr-romanshorn.ch</p>

    <h3>Allgemeiner Hinweis</h3>
    <p>Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
    <p>Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäss der nachfolgenden Beschreibung einverstanden. Diese Website kann grundsätzlich ohne Registrierung besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden soweit nötig auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.</p>

    <h3>Bearbeitung von Personendaten</h3>
    <p>Eine betroffene Person ist eine Person, über die Personendaten bearbeitet werden. Bearbeiten umfasst jeden Umgang mit Personendaten, unabhängig von den angewandten Mitteln und Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen, Löschen, Speichern, Verändern, Vernichten und Verwenden von Personendaten. Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht. Wir bearbeiten Personendaten für jene Dauer, die für den jeweiligen Zweck oder die jeweiligen Zwecke erforderlich ist. Bei länger dauernden Aufbewahrungspflichten aufgrund von gesetzlichen und sonstigen Pflichten, denen wir unterliegen, schränken wir die Bearbeitung entsprechend ein.</p>

    <h3>Datenschutzerklärung für Server-Log-Files</h3>
    <p>Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
    <ul>
      <li>Browsertyp und Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>Uhrzeit der Serveranfrage</li>
    </ul>
    <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zuprüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>

    <h3>Dienste von Dritten</h3>
    <p><strong>Mapbox</strong></p>
    <p>Diese Website verwendet <i>Mapbox</i>/Leaflet für das Einbetten von Karten.</p>
    <p>Beim Besuch von <i>Mapbox</i>-Websites erhält <i>Mapbox</i> automatisch bestimmte Informationen wie: (a) Browsertyp und Gerätetyp, (b) Betriebssystem und (c) verweisende Webseiten einschließlich der besuchten Seiten auf solchen Websites. Und Informationen wie die IP-Adresse, Daten, die über unbedingt erforderliche und akzeptierte Website-Cookies / ähnliche Technologien gesammelt werden. Weitere Informationen zu Cookies auf der <i>Mapbox</i>-Website, Einstellungen und Anweisungen zur Änderung der Browsereinstellungen für Cookies finden Sie bitte auf der <i>Mapbox</i>-Website <a target="_blank" href="https://www.mapbox.com/legal/cookies">hier</a>.</p>
    <p><strong>Google Web Fonts</strong></p>
    <p>Die für die Darstellung der Seite genutzten <i>Google Webfonts</i> werden lokal gehostet und es findet kein Datenaustausch mit <i>Google</i> statt.</p>
    <p><strong>Analytics</strong></p>
    <p>Für die Optimierung des Angebots und die Erkennung von Problemen verwendet diese Webseite <i>Matomo</i>, eine quelloffene Software. Diese sammelt folgende Nutzungsdaten:</p>
    <ul>
      <li>Cookies</li>
      <li>Anonymisierte IP-Adressen</li>
      <li>Datum und Uhrzeit</li>
      <li>Seitentitel, URLs, UserAgent</li>
      <li>Gerätetyp, Bildschirmauflösung</li>
      <li>externe Links</li>
    </ul>

    <h3>Allgemeiner Haftungsausschluss</h3>
    <p>Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, unser Informationsangebot aktuell, inhaltlich richtig und vollständig anzubieten. Trotzdem kann das Auftreten von Fehlern nicht völlig ausgeschlossen werden, womit wir keine Garantie für Vollständigkeit, Richtigkeit und Aktualität von Informationen auch journalistisch-redaktioneller Art übernehmen können. Haftungsansprüche aus Schäden materieller oder ideeller Art, die durch die Nutzung der angebotenen Informationen verursacht wurden, sind ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.</p>
    <p>Der Herausgeber kann nach eigenem Ermessen und ohne Ankündigung Texte verändern oder löschen und ist nicht verpflichtet, Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser Website geschieht auf eigene Gefahr des Besuchers. Der Herausgeber, seine Auftraggeber oder Partner sind nicht verantwortlich für Schäden, wie direkte, indirekte, zufällige, vorab konkret zu bestimmende oder Folgeschäden, die angeblich durch den Besuch dieser Website entstanden sind und übernehmen hierfür folglich keine Haftung.</p>
    <p>Der Herausgeber übernimmt ebenfalls keine Verantwortung und Haftung für die Inhalte und die Verfügbarkeit von Website Dritter, die über externe Links dieser Website erreichbar sind. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert sich damit ausdrücklich von allen Inhalten Dritter, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.</p>

    <h3>Änderungen</h3>
    <p>Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise informieren.</p>
  </div>
  </template>

<script>

export default{
  name: 'InfoView',
  components: {
  },
  props: [
    'locations',
    'categories',
    'tags',
  ],
  inject: [
    'showLocation'
  ]
}
</script>

<style lang="less" scoped>
.fullscreen-view{
  padding-left:  var(--side-padding);
  padding-right: var(--side-padding);
  max-width: 40em;
  margin: 0 auto;
}
</style>
