<template>
  <div class="category" @click="setFilter({'type':'category','id':category.tid})">
    <div class="icon">
      <InlineSvg :name="category.icon" />
    </div>
    <div class="info">
      <h2 v-html="category.name"></h2>
      <p>{{ category.count }}  {{ category.count == 1 ? "Angebot" : "Angebote" }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleCategory',
  props: {
    category: {type: Object, required: true}, // provide vocabulary of categories including their icons
  },
  data(){
    return {
    }
  },
  inject: [
    'setFilter',
    'changePage',
  ],
}
</script>

<style lang="less" scoped>
@import "../less/defaults";
.category{
    display: flex;
    align-items: flex-start;
    padding: 0em;
    padding-bottom: 0.5em;
    cursor: pointer;
    .icon{
      flex: 0 0 3em;
      margin-top:0.5em;
    }
    .info{
      padding: 0.25em 0.5em 0;
      flex: 1 1 auto;
      h1, h2, h3, h4{
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
</style>
