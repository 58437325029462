import { createApp } from 'vue'
import App from './App.vue'
import InlineSvg from "@/components/InlineSvg.vue"
import './registerServiceWorker'
// import VueMatomo from "vue-matomo"

let mapapp = createApp(App);
mapapp.component("InlineSvg", InlineSvg);

// mapapp.use(VueMatomo, {
//   host: "https://statistik.patrickitten.ch",
//   siteId: 6,
//   trackerFileName: 'matomo',
//   // router: router,
//   enableLinkTracking: true,
//   requireConsent: false,
//   trackInitialView: true,
//   disableCookies: false,
//   enableHeartBeatTimer: false,
//   heartBeatTimerInterval: 15,
//   debug: false,
//   userId: undefined,
//   cookieDomain: undefined,
//   domains: undefined,
//   preInitActions: []
// });

mapapp.mount('#app')
