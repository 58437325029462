<template>
  <transition name="fade">
    <div v-if="popupVisible" class="popup">
      <img v-if="popup.image" class="image" :src="popup.image" :alt="popup.image-alt" />
      <div class="container">
        <h2>{{ popup.title }}</h2>
        <div class="content" v-html="popup.content"></div>
        <!-- Details-Button -->
        <div v-if="hasTagLink" class="button" v-html="popup.button_text" @click="setFilter({'type':'tag','id':popup.button_tags}); popupVisible = false"></div>
        <a v-if="hasUrlLink" class="button" v-html="popup.button_text" :href="popup.button_link" target="_blank"></a>
        <!-- Close-Button -->
        <button @click="popupVisible = false" class="close"><InlineSvg name="close" /></button>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div v-if="popupVisible" class="overlay background" @click="popupVisible = false"></div>
  </transition>
</template>

<script>

export default{
  name: 'PopUp',
  props: {
    popup: {type: Object, required: false}
  },
  data(){
    return {
      popupVisible: true,
      hasTagLink: (this.popup.button_text && this.popup.button_tags) ? true : false,
      hasUrlLink: (this.popup.button_text && this.popup.button_link) ? true : false,
    }
  },
  inject: [
    'setFilter'
  ]
}
</script>

<style lang="less" scoped>
@import "../less/defaults";
.popup{
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80vw;
  max-width: 20em;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button.close{
    position: absolute;
    top: -1em;
    right: -1em;
    margin:0;
    padding: 0.25em;
    background: white;
    border: none;
    width:  2.5em;
    height: 2.5em;
    border-radius:99999px;
    cursor: pointer;
  }
  .container{
    position: relative;
    padding: 1.5em;
    background-color: fade(@color-yellow, 100%);
    text-align: center;
    h2{
      margin-top:0;
    }
    // div.content /deep/ p:last-child{
    //   margin-bottom:0;
    // }
  }
  img.image{
    width:80vw;
    max-width: 15em;
    height: auto;
  }
}
</style>
